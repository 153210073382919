export default {
  props: {
    currentStep: {
      type: Number,
      required: true,
    },

    steps: {
      type: Number,
      required: true,
    },

    label: {
      type: String,
      default: '',
    },
  },

  computed: {
    setProgressBar() {
      return (parseFloat(100 / this.steps) * (this.currentStep - 1)).toFixed()
    },
  },
}
